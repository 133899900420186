<form [formGroup]="formGroup" (ngSubmit)="onSubmitForm()">
  <div class="formgrid grid p-fluid">
    <div class="col-12">
      <app-target-code-selector
        [label]="moduleFieldString + '.authorityDocument.label' | translate: 'Authority Document'"
        [placeholder]="moduleFieldString + '.authorityDocument.placeholder' | translate: 'Enter Authority Document'"
        [control]="formGroup?.controls?.authorityDocument"
        [viewMode]="fieldViewMode"
        [multi]="false"
        [targetTypes]="[authorityType]"
        [customProjectionFields]="['code', 'recordStatus', 'label', 'name', 'uuid', 'uniqueId']"
        [tableMode]="false"
        (onChangesObject)="onAuthorityDocumentChange($event)"
      >
      </app-target-code-selector>
    </div>
    <div class="col-12 md:col-6">
      <app-basic-input
        [label]="moduleFieldString + '.title.label' | translate: 'Title'"
        [placeholder]="moduleFieldString + '.title.placeholder' | translate: 'Enter Title'"
        [control]="formGroup?.controls?.title"
        [viewMode]="fieldViewMode"
      ></app-basic-input>
    </div>

    <div class="col-12 md:col-6">
      <app-basic-input
        [label]="moduleFieldString + '.frameworkRef.label' | translate: 'Framework Reference'"
        [placeholder]="moduleFieldString + '.frameworkRef.placeholder' | translate: 'Enter Framework Reference'"
        [control]="formGroup?.controls?.frameworkRef"
        [viewMode]="fieldViewMode"
      ></app-basic-input>
    </div>

    <div class="col-12 md:col-6">
      <app-basic-input
        [label]="moduleFieldString + '.name.label' | translate: 'Name'"
        [placeholder]="moduleFieldString + '.name.placeholder' | translate: 'Enter Name'"
        [control]="formGroup?.controls?.name"
        [viewMode]="fieldViewMode"
      ></app-basic-input>
    </div>

    <div class="col-12 md:col-6">
      <app-target-code-selector
        [excludeCodes]="data?.code ? [data?.code] : []"
        [label]="moduleFieldString + '.parent.label' | translate: 'Parent Citation'"
        [placeholder]="moduleFieldString + '.parent.placeholder' | translate: 'Enter Parent Citation'"
        [control]="formGroup?.controls?.parent"
        [viewMode]="fieldViewMode"
        [multi]="false"
        [extraFilters]="
          formGroup?.controls?.authorityDocument?.value || data?.authorityDocument || authID
            ? [
                {
                  property: 'authorityDocument',
                  operation: 'EQUAL',
                  value: formGroup?.controls?.authorityDocument?.value || data?.authorityDocument || authID,
                },
              ]
            : []
        "
        [targetTypes]="[citationType]"
      >
      </app-target-code-selector>
      <!-- <app-target-code-selector
      [excludeCodes]="data?.code ? [data?.code] : []"
      [label]="'Parent Citation'"
      [placeholder]="'Search Citations'"
      [control]="formGroup?.controls?.parent"
      [viewMode]="fieldViewMode"
      [multi]="false"
      [optionLabel]="'name'"
      [optionValue]="'code'"
      [extraFilters]="(formGroup?.controls?.authorityDocument?.value || data.authorityDocument || authID) ? [{'property': 'authorityDocument', 'operation': 'EQUAL', 'value': (formGroup?.controls?.authorityDocument?.value || data.authorityDocument || authID)}] : []"
      [targetTypes]="[citationType]"
      >
    </app-target-code-selector> -->
    </div>
    <!-- <div class="col-12 md:col-6">
  <app-basic-input
    label="Framework Code"
    [placeholder]="'Enter Framework Code'"
    [control]="formGroup?.controls?.frameworkCode"
    [viewMode]="fieldViewMode"
  ></app-basic-input>
</div> -->

    <div class="col-12 md:col-6">
      <app-target-code-selector
        [label]="moduleFieldString + '.citationType.label' | translate: 'Type'"
        [placeholder]="moduleFieldString + '.citationType.placeholder' | translate: 'Enter Citation Type'"
        [control]="formGroup?.controls?.citationType"
        [viewMode]="fieldViewMode"
        [multi]="false"
        [targetTypes]="[citationTypesType]"
      >
      </app-target-code-selector>
    </div>
    <!-- <div class="col-12 md:col-6">
<app-drop-down-input
  [label]="'Type'"
  [placeholder]="'Select Type'"
  [name]="'type'"
  [optionLabel]="'label'"
  [items]="listOfTypes"
  [optionValue]="'value'"
  [control]="formGroup?.controls?.type"
  [viewMode]="fieldViewMode"
  [badgeView]="true"
></app-drop-down-input>
</div> -->

    <!-- <div class="col-12 md:col-6">
<app-drop-down-input
  [label]="'Status'"
  [placeholder]="'Select Status'"
  [name]="'status'"
  [optionLabel]="'label'"
  [items]="listOfStatuses"
  [optionValue]="'value'"
  [control]="formGroup?.controls?.status"
  [viewMode]="fieldViewMode"
  [badgeView]="true"
></app-drop-down-input>
</div> -->

    <div class="col-12">
      <app-switch-input
        [label]="moduleFieldString + '.mandatory.label' | translate: 'Mandatory'"
        [name]="'allowExceptions'"
        [control]="formGroup?.controls?.mandatory"
        [viewMode]="fieldViewMode"
      >
      </app-switch-input>
    </div>

    <div class="col-12 md:col-12">
      <app-text-editor
        [name]="'description'"
        [label]="moduleFieldString + '.description.label' | translate: 'Description'"
        [placeholder]="moduleFieldString + '.description.placeholder' | translate: 'Enter Description'"
        [control]="formGroup?.controls?.description"
        [viewMode]="fieldViewMode"
        [advanced]="true"
      ></app-text-editor>
    </div>
    <!-- <div class="col-12 md:col-6">
<app-text-editor
  [name]="'notes'"
  label="Notes"
  [placeholder]="'Enter Text...'"
  [control]="formGroup?.controls?.notes"
  [viewMode]="fieldViewMode"
  [advanced]="true"
></app-text-editor>
</div> -->
  </div>
  <div class="flex flex-row-reverse justify-content-between align-items-center">
    <div class="flex flex-row-reverse gap-2">
      @if (showSaveButton && showSaveAndClose) {
        <app-button [action]="submitButtonAction"></app-button>
      }
      @if (showSaveButton) {
        <app-button [action]="submitSaveButtonAction"></app-button>
      }
      @if (showInPlaceButton) {
        <app-button [action]="submitInPlaceButtonAction"></app-button>
      }
      @if (fieldViewMode == 'create') {
        <app-button [action]="cancelButtonAction"></app-button>
      }
    </div>
    @if (fieldViewMode == 'create') {
      <app-switch-input
        [label]="'general.messages.create another record' | translate"
        [name]="'inPlaceCreate'"
        [labelWidthFit]="true"
        [control]="inPlaceCreateControl"
        [viewMode]="'create'"
        [styleClass]="'mb-0'"
      >
      </app-switch-input>
    }
  </div>
</form>
