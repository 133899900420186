import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import {
  AuthorityDocumentDto,
  BaseForm,
  Citation,
  FilterItem,
  ModuleKeywords,
  TargetTypeEnum,
  getEnumOptions,
} from '@shared/classes';
import { ViewModeService } from '@shared/services/view-mode.service';
import { AuthorityDataService } from '../../service/data/authority-data.service';
import { CitationDataService } from '../../service/data/citation-data.service';

@Component({
  selector: 'app-citation-item-form',
  templateUrl: './citation-item-form.component.html',
  styleUrls: ['./citation-item-form.component.scss'],
})
export class CitationItemFormComponent extends BaseForm<Citation> implements OnInit {
  // listOfTypes = getEnumOptions(Citation.TypeEnum);
  listOfStatuses = getEnumOptions(Citation.RecordStatusEnum);

  authID: string = null;
  parentID: string = null;
  authorityType: TargetTypeEnum = TargetTypeEnum.AuthorityDocument;
  citationType: TargetTypeEnum = TargetTypeEnum.Citation;
  citationTypesType: TargetTypeEnum = TargetTypeEnum.CitationType;
  citationParentFilter: FilterItem;
  linkedAuthorityDocument: AuthorityDocumentDto;
  @Output() submitData: EventEmitter<any> = new EventEmitter();

  constructor(
    public viewModeService: ViewModeService,
    private route: ActivatedRoute,
    private citationReqService: CitationDataService,
    private authorityService: AuthorityDataService
  ) {
    super(viewModeService, ModuleKeywords.Citation);
    this.route.params.subscribe({
      next: (params) => {
        this.parentID = params['parentID'];
        this.authID = params['authID'];
        this.citationParentFilter = { property: 'authorityDocument', operation: 'EQUAL', value: this.authID };
        //   this.initFormStructure();
        this.getRequiredData();
      },
    });
    this.submitSaveButtonAction = {
      ...this.submitSaveButtonAction,
      command: this.customSubmit.bind(this, this.submitSaveButtonAction.command),
    };
  }

  ngOnInit(): void {}

  getData() {
    return this.viewModeService.viewMode == 'create'
      ? this.getDataKeyValueFormat()
      : this.getChangedFormValues().updateItems;
  }

  setData(data: any) {
    this.patchFormData({ ...data });
    this.data = data;
    if (this?.data?.authorityDocument) {
      this.authorityService.getByIdOrCode(this?.data?.authorityDocument).subscribe((res) => {
        this.linkedAuthorityDocument = res.data;
        this.calculateName();
      });
    }
    this.formGroup?.controls?.title.valueChanges.subscribe((t) => {
      this.calculateName();
    });

    this.formGroup?.controls?.frameworkRef.valueChanges.subscribe((t) => {
      this.calculateName();
    });
  }

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      name: new FormControl({ value: null, disabled: true }, Validators.required),
      title: new FormControl(null, Validators.required),
      // frameworkCode: new FormControl(null, Validators.required),
      frameworkRef: new FormControl(null, Validators.required),
      parent: new FormControl(null),
      citationType: new FormControl(null, Validators.required),
      // type: new FormControl(null),
      authorityDocument: new FormControl({ value: null, disabled: true }, [Validators.required]),
      mandatory: new FormControl(false),
      // status: new FormControl(null),
      description: new FormControl(null),
      notes: new FormControl(null),
    });
  }
  getRequiredData() {
    if (this.parentID) {
      this.formGroup.patchValue({ parent: this.parentID });
      // this.citationReqService.getByIdOrCode<Citation>(this.parentID).pipe(takeUntil(this.destroy$)).subscribe(res=>{
      //     if(!this.data?.parent){
      //         res.data
      //         this.formGroup.patchValue({parent:res.data});
      //         this.data = {...this.data, parent:res.data};
      //     }
      // })
    }
    if (this.authID) {
      this.formGroup.patchValue({ authorityDocument: this.authID });
      // this.authorityReqService.getByIdOrCode<AuthorityDocument>(this.authID).pipe(takeUntil(this.destroy$)).subscribe(res=>{
      //     if(!this.data?.authorityDocument){
      //         res.data
      //         this.formGroup.patchValue({authorityDocument:res.data});
      //         this.data = {...this.data, authorityDocument:res.data};
      //     }
      // })
      this.authorityService.getByIdOrCode(this.authID).subscribe((res) => {
        this.linkedAuthorityDocument = res.data;
        this.calculateName();
      });
    } else {
      this.formGroup?.controls?.authorityDocument?.enable();
    }
  }
  onAuthorityDocumentChange(value) {
    this.linkedAuthorityDocument = value;
    this.calculateName();
  }
  calculateName() {
    let newCode = this.formGroup?.controls?.frameworkRef?.value ?? '';
    let newTitle = this.formGroup?.controls?.title?.value ?? '';

    this.formGroup.patchValue({
      name: `${this.linkedAuthorityDocument?.uniqueId} ${newCode ? `(${newCode}): ` : ''}${newTitle}`.trim(),
    });
  }

  customSubmit(command) {
    this.submitData.emit(this.formGroup?.getRawValue());
    command();
  }
}
